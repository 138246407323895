import Component from '../core/Component'

export default class Bar extends Component {
    constructor(element) {
        super(element);
    }

    prepare() {
        if (this.element.tagName.toLocaleLowerCase() !== "a") {
            this.element.addEventListener('click', event => {
                event.preventDefault();
                Booking.Open();
            });
        }
    }
}