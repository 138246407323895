import { query, queryAll } from '../utils/dom';

export default class Component {

    constructor(element) {
        this.element = element;
        this.element['__giant_component__'] = this;
        this._ref = {};
        this.ref = {};
    }

    get ref() {
        return this._ref;
    }

    set ref(items) {
        if (Object.keys(items).length == 0) {
            this._ref = this._getRefElements();
        } else {
            this._ref = {};
            this._ref = this._getRefElements(items);
        }

        return this._ref;
    }

    async require() {
    }

    _load() {
        this.require().then(::this.prepare)
    }

    prepare() {
        console.warn(`Component ${this._name} does not have "prepare" method.`);
    }

    destroy() {
        //console.warn('Destroy method: override me');
    }

    is(state) {
        return this.element.classList.contains(state);
    }

    getElement() {
        return this.element;
    }

    getRef(ref, prefixed = false) {
        return `[data-ref="${prefixed ? `${this._name}:` : ''}${ref}"]`;
    }

    _getRefElements(items = null) {
        if (items == null) {
            items = {};

            queryAll('[data-ref]', this.element).forEach(item => {
                let name = item.dataset.ref;
                let multiple = false;

                if (items[name] != null) {
                    return true;
                }

                if (name.includes('[]')) {
                   multiple = true;
                   name = name.replace('[]', '');
                }

                if (name.split(':').length > 1) {
                    if (name.split(':')[0] == this._name) {
                        if (multiple) {
                            items[ name.split(':')[1] ] = [];
                        } else {
                            items[ name.split(':')[1] ] = null;
                        }
                    }
                } else {
                    if (multiple) {
                        items[ name ] = [];
                    } else {
                        items[ name ] = null;
                    }
                }
            });
        }

        Object.keys(items)
            .forEach(key => {
                if (Array.isArray(items[key])) {
                    let elements = queryAll(this.getRef(key + '[]', true), this.element);
                    if (elements.length === 0) {
                        elements = queryAll(this.getRef(key.slice(0, -1), true), this.element);
                        if (elements.length === 0) {
                            elements = queryAll(this.getRef(key + '[]'), this.element)
                            if (elements.length === 0) {
                                elements = queryAll(this.getRef(key.slice(0, -1)), this.element)
                            }
                        }
                    }
                    this._ref[key] = elements;
                } else if (!items[key]) {
                    let element = query(this.getRef(key, true), this.element);
                    if (!element) {
                        element = query(this.getRef(key), this.element);
                    }
                    this._ref[key] = element;
                } else {
                    this._ref[key] = items[key];
                }
            });

        return this._ref;
    }

    static getFromElement(element) {
        return element['__giant_instance__'];
    }

    state = {}

    setState(newState) {

        let stateChanges = {};

        Object.keys(newState).forEach(key => {
            if(typeof newState[key] === 'boolean' || typeof newState[key] === 'string' || typeof newState[key] === 'number' || typeof newState[key] === 'undefined') {
                if(this.state[key] !== newState[key]) {
                    stateChanges[key] = newState[key];
                }
            } else if (newState[key].constructor === Array) {
                if(this.state[key] != null) {
                    stateChanges[key] = [];
                    newState[key].forEach((item, index) => {
                        //console.log(this.state[key] != null, this.state[key], newState[key])
                        if(this.state[key][index] !== newState[key][index]) {
                            stateChanges[key][index] = newState[key][index];
                        }
                    });
                } else {
                    stateChanges[key] = newState[key];
                }
            } else if (typeof newState[key] === 'object') {
                stateChanges[key] = {};
                Object.keys(newState[key]).forEach(subkey => {
                    stateChanges[key][subkey] = newState[key][subkey];
                 });
             }
        });

        Object.keys(stateChanges).forEach(key => {
            if (newState[key].constructor === Array && stateChanges[key].length == 0) {
                delete stateChanges[key];
            } else if (typeof newState[key] === 'object' && Object.keys(stateChanges[key]).length == 0) {
                delete stateChanges[key];
            }
        });

        this.stateChange(stateChanges);
        this.state = newState;
    }

    stateChange(stateChanges) {}

}