import Component from '../core/Component'
import throttle from 'lodash/throttle'

export default class Bar extends Component {
    constructor(element) {
        super(element);
        this.scrollHandler = throttle(::this.scroll);
        this.resizeHandler = throttle(::this.resize);
        this.resize();
    }

    prepare() {
        window.addEventListener('scroll', this.scrollHandler);
        window.addEventListener('resize', this.resizeHandler);
        this.ref.submit.addEventListener('click', event => {
            event.preventDefault();
            let from = new Date(window.$(this.ref.from).datepicker('getDate').toUTCString());
            let to = new Date(window.$(this.ref.to).datepicker('getDate').toUTCString());
            let settings = {checkin: (from.getFullYear())+"-"+(from.getMonth() + 1)+"-"+from.getDate(), checkout: (to.getFullYear())+"-"+(to.getMonth() + 1)+"-"+to.getDate() };

            if (this.element.dataset.id) {
                let idOption = this.element.dataset.id.split(':');
                settings[idOption[0]] = idOption[1];
            }
            Booking.Open(settings);
        });

        this.setState({
            show: true
        });
    }

    destroy() {
        window.removeEventListener('scroll', this.scrollHandler);
        window.removeEventListener('resize', this.resizeHandler);
    }

    scroll(event) {
        if(window.pageYOffset > this.limit) {
            this.setState({
                ...this.state,
                show: false,
            });
        } else {
            this.setState({
                ...this.state,
                show: true,
            });
        }
    }

    resize() {
        let body = document.body,
            html = document.documentElement;
        let height = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
        this.limit = height - window.innerHeight - 500;
    }

    stateChange(stateChanges) {
        if('show' in stateChanges) {
            let show = stateChanges.show ? "add" : "remove";
            document.body.classList[show]('bar-is-active');
        }
    }
}