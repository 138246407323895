// general
import Antispam from '../../components/Antispam'
import CookieBubble from '../../components/CookieBubble'
import Tabs from '../../components/Tabs'

// project
import Bar from './Bar'
import Carousel from './Carousel'
import Datepicker from './Datepicker'
import Explode from './Explode'
import Hero from './Hero'
import Map from './Map'
import MenuOpener from './MenuOpener'
import Reservation from './Reservation'
import ReviewSlider from './ReviewSlider'
import Unfold from './Unfold'
import Video from './Video'

const Components = {
    Antispam,
    Bar,
    Carousel,
    CookieBubble,
    Datepicker,
    Explode,
    Hero,
    Map,
    MenuOpener,
    Reservation,
    ReviewSlider,
    Tabs,
    Unfold,
    Video,
}

export default Components